<template>
    <div class="claim">
        <el-dialog
            :title="$t('qi-ye-ren-ling')"
            :visible.sync="visible"
            width="600px"
            center
            append-to-body
            destroy-on-close
        >
            <el-form size="small" :model="form" ref="form" :rules="rules" label-width="175px">
                <el-form-item :label="$t('xing-ming')" prop="nickname">
                    <el-input
                        class="input2"
                        v-model="form.nickname"
                        size="normal"
                        clearable
                        :placeholder="$t('qing-shu-ru-xing-ming')"
                    ></el-input>
                </el-form-item>

                <el-form-item :label="$t('gong-si-ji-gou-yuan-xiao-ming-cheng')" prop="vendorId">
                    <el-input
                        class="input2"
                        v-model="form.companyName"
                        size="normal"
                        :placeholder="$t('qing-shu-ru-gong-si-ming-cheng')"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('zhi-wei')" prop="position">
                    <el-input
                        class="input2"
                        v-model="form.position"
                        :placeholder="$t('qing-shu-ru-zhi-wei')"
                        size="normal"
                        clearable
                    ></el-input>
                </el-form-item>

                <el-form-item :label="$t('shou-ji-hao')" prop="phone">
                    <el-input
                        class="input2"
                        v-model="form.phone"
                        :placeholder="$t('qing-shu-ru-shou-ji-hao')"
                        size="normal"
                        clearable
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('you-xiang')" prop="email">
                    <el-input
                        class="input2"
                        v-model="form.email"
                        :placeholder="$t('qing-shu-ru-you-xiang')"
                        size="normal"
                        clearable
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('liu-yan')" prop="email">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 4, maxRows: 8 }"
                        :maxlength="50"
                        show-word-limit
                        class="input2"
                        :placeholder="$t('bei-zhu-qi-ta-nei-rong-xin-xi')"
                        v-model="form.remark"
                        resize="none"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item lable-width="0px">
                    <el-button type="warning" :loading="loading" size="small" @click="onSubmit">
                        {{ $t('ti-jiao') }}
                    </el-button>
                    <el-button @click="close" size="small">{{ $t('qu-xiao') }}</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'claim',
    props: {
        vendorId: {
            type: Number,
            default: 0
        },
        vendorUserId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            visible: false,
            form: {},
            rules: {},
            loading: false
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    mounted() {
        // this.show();
    },
    methods: {
        onSubmit() {
            const formData = { ...this.form };
            this.$http.post('/enterpriseClaim/save', formData, { body: 'json' }).then(res => {
                this.$message.success(this.$t('ti-jiao-cheng-gong'));
                this.close();
                if (this.vendorUserId) {
                    this.$router.push('/chat?otherUserId=' + this.vendorUserId);
                }
            });
        },
        close() {},
        show() {
            this.checkLogin()
                .then(() => {
                    if (this.vendorId === this.userId) {
                        return;
                    }
                    this.form = {
                        vendorId: this.vendorId,
                        nickname: this.userInfo.nickname,
                        position: this.userInfo.position,
                        phone: this.userInfo.phone,
                        email: this.userInfo.email,
                        companyName: this.getName(this.userInfo, ['chCompanyName', 'enCompanyName']),
                        userId: this.userInfo.id
                    };
                    this.visible = true;
                })
                .catch(e => {
                    console.log(e);
                    if (e === '未登录') {
                        this.login();
                    } else if (e && e.error) {
                        this.$message.warning(e.error);
                    }
                });
        },
        close() {
            this.visible = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.el-button {
    min-width: 100px;
    height: 40px;
}
</style>
