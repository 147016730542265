<template>
    <div class="store">
        <StoreHeader :loading="loading" :vendorInfo="vendorInfo"></StoreHeader>

        <div class="storeMain" v-loading="loading">
            <router-view :vendorInfo="vendorInfo" @setStore="setStore" />
        </div>
    </div>
</template>
<script>
import StoreHeader from '../../components/brand/StoreHeader.vue';
import { mapState } from 'vuex';
import { saveStatistics } from '../../utils/CommonApi';

export default {
    name: 'Store',
    data() {
        return {
            storeId: 0,
            vendorInfo: {},
            loading: true
        };
    },
    components: {
        StoreHeader
    },
    computed: {
        ...mapState(['userInfo'])
    },
    mounted() {
        window.addEventListener('beforeunload', this.beforeunloadEvent);
    },
    watch: {
        storeId() {
            if (this.storeId) {
                saveStatistics(this.storeId, 'VENDOR');
            }
        }
    },
    methods: {
        setStore(id) {
            this.storeId = id;
            this.getStoreInfo();
        },
        getStoreInfo() {
            this.$http.get('/vendorInfo/getHome/' + this.storeId).then(res => {
                this.vendorInfo = res;
                this.$nextTick(() => {
                    this.loading = false;
                });
            });
        },
        beforeunloadEvent(e) {
            saveStatistics(this.storeId, 'VENDOR', true);
        }
    },
    beforeRouteLeave(to, from, next) {
        saveStatistics(this.storeId, 'VENDOR', true);
        next();
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.store {
    background-color: #fff;
}

.storeMain {
    background: #f5f7fa;
    position: relative;
    z-index: 1;
}

.storeMain {
    min-height: calc(100vh - 605px - 50px);
    box-sizing: border-box;
}
@media screen and (max-height: 1200px) {
    .storeMain {
        min-height: calc(1200px - 605px - 50px);
    }
}
</style>
