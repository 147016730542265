<template>
    <div :style="defaultStyle" class="store">
        <el-header height="90px" id="header" class="header" :style="{ left: 0 - left + 'px' }">
            <div class="header-main">
                <img class="logo" @click="$router.push('/home')" src="../../assets/nav_logo_02.png" alt="" />

                <div class="center">
                    <el-input v-model="searchVal" class="input3" :placeholder="$t('sou-suo')"></el-input>

                    <el-button type="primary" @click="goSearch('all')">{{ $t('sou-quan-zhan') }}</el-button>
                    <el-button type="warning" @click="goSearch()">{{ $t('sou-ben-dian') }}</el-button>
                </div>
                <HeaderUser />
            </div>
        </el-header>

        <div class="storeHeader" :style="{ left: 0 - left + 'px' }">
            <div class="storeMain">
                <div class="storeInfo">
                    <div class="storeLogo" v-loading="loading">
                        <auto-img
                            class="logoImg"
                            :src="vendorInfo.logo1 + '?x-oss-process=image/resize,h_204,m_lfit'"
                            mul="42.5%"
                            :path="`/brandDetail?id=${storeId}`"
                        ></auto-img>
                    </div>
                    <div class="store-center">
                        <div class="store-title">
                            <span>{{ getName(vendorInfo, ['chCompanyName', 'enCompanyName']) }}</span>
                            <img src="../../assets/icon_qiyerenzheng.png" alt="" class="qi" />
                            <img class="vip" src="../../assets/icon_vip.png" alt="" />
                        </div>

                        <div class="text1" v-if="isChinese">
                            {{ getName(vendorInfo, ['chCompanyName', 'enCompanyName'], true) }}
                        </div>
                        <el-link class="text2" @click="$refs.claim.show()">
                            {{ $t('qi-ye-ren-ling') }}
                        </el-link>
                    </div>

                    <div class="store-cor">
                        <el-button type="text" :class="{ active: isCollection }" @click="collection($event)">
                            <div class="btn-content">
                                <div>
                                    <span class="iconfont">{{ isCollection ? '&#xe8c3;' : '&#xe616;' }}</span>
                                </div>
                                <span>{{ $t('guan-zhu-zhan-shang') }}</span>
                            </div>
                        </el-button>

                        <el-popover placement="bottom" trigger="hover">
                            <el-button type="text" @click.stop.prevent="" slot="reference">
                                <div class="btn-content">
                                    <span class="iconfont">&#xe617;</span>
                                    <span>{{ $t('fen-xiang-zhan-shang') }}</span>
                                </div>
                            </el-button>
                            <share isBrand :info="vendorInfo" v-show="isChinese"></share>
                            <share isBrand :info="vendorInfo" local="en" v-show="!isChinese"></share>
                        </el-popover>
                        <!-- <el-button type="warning" icon="el-icon-s-comment">
                        </el-button> -->
                        <el-button type="warning" class="zixun" @click="advisory(vendorInfo, 'vendor')">
                            <div class="btn-content">
                                <span class="iconfont">&#xe615;</span>
                                <span class="text">{{ $t('zi-xun-1') }}</span>
                            </div>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="menu"
            :style="{
                left: fixed ? 0 - left + 'px' : 0,
                backgroundColor: color,
                position: fixed ? 'fixed' : 'relative',
                top: fixed ? '90px' : '0px'
            }"
        >
            <div class="menu-center">
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
                    <el-menu-item index="1">
                        <router-link :to="`/brandDetail?id=${storeId}`"
                            >{{ $t('guan-yu')
                            }}{{ getName(vendorInfo, ['chCompanyName', 'enCompanyName']) }}</router-link
                        ></el-menu-item
                    >
                    <el-submenu index="2">
                        <template slot="title">{{ $t('chan-pin-mu-lu') }}</template>
                        <el-menu-item index="2-1">
                            <router-link :to="`/brandProductList?vendorInfoId=${storeId}`">{{
                                $t('quan-bu-chan-pin')
                            }}</router-link>
                        </el-menu-item>
                        <el-menu-item :index="`2-${index + 2}`" v-for="(item, index) in menus" :key="item.id">
                            <router-link :to="`/brandProductList?vendorInfoId=${storeId}&customCateId=${item.id}`">
                                {{ getName(item) }}
                            </router-link>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item index="3">
                        <router-link :to="`/brandNews?id=${storeId}`"
                            >{{ $t('xin-wen-zi-xun') }}
                        </router-link></el-menu-item
                    >
                </el-menu>
            </div>
        </div>

        <Claim ref="claim" :vendorId="storeId" :vendorUserId="vendorInfo.userId" />
    </div>
</template>
<script>
import HeaderUser from '../../components/header/HeaderUser';
import UserStoreOperation from '../../mixins/UserStoreOperation';
import Claim from './Claim.vue';
import Share from '../Share.vue';
export default {
    name: 'Store',
    mixins: [UserStoreOperation],
    data() {
        return {
            defaultStyle: { height: '296px', backgroundColor: '#FFFFFF' },
            activeIndex: '2',
            searchVal: '',
            left: 0,
            collectionType: 'VENDOR',
            menus: [],
            decorationInfo: {},
            fixed: false
        };
    },
    props: {
        vendorInfo: {
            type: Object,
            default: () => {
                return {
                    userId: 0
                };
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        storeId() {
            return this.vendorInfo.id;
        },
        categories() {
            return this.vendorInfo.categories || [];
        },
        color() {
            return !this.loading ? this.decorationInfo.navigationColor || '#0F264D' : '#fff';
        }
    },
    watch: {
        storeId() {
            if (this.storeId) {
                this.$nextTick(() => {
                    this.cId = this.storeId;
                });
                this.getSelect();
            }
        }
    },
    components: {
        HeaderUser,
        Claim,
        Share
    },
    methods: {
        getSelect() {
            this.$http
                .get('/customCategory/my', {
                    vendorId: this.storeId
                })
                .then(res => {
                    this.menus = res;
                    this.loading = false;
                });

            this.$http
                .post(
                    '/decoration/all',
                    {
                        query: {
                            vendorInfoId: this.storeId
                        }
                    },
                    { body: 'json' }
                )
                .then(res => {
                    if (res.numberOfElements > 0) {
                        this.decorationInfo = res.content[0];
                    }
                });
        },
        goSearch(type) {
            if (type) {
                this.$router.push({
                    name: 'search',
                    query: {
                        search: this.searchVal,
                        searchType: 'product'
                    }
                });
            } else {
                this.$router.push({
                    name: 'brandProductList',
                    query: {
                        search: this.searchVal,
                        vendorInfoId: this.storeId,
                        firstSearch: 1
                    }
                });
            }
        },
        scrollEvent() {
            this.left = document.documentElement.scrollLeft;

            if (document.documentElement.scrollTop >= 252 - 90) {
                this.fixed = true;
            } else {
                this.fixed = false;
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollEvent);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollEvent);
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.header {
    background-color: transparent;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    padding: 0;
    // width: 100%;
    right: 0;
    background-color: #f5f7fa;
    min-width: 1200px;
}
.header-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    // max-width: 1920px;
    // padding: 0 50px;
    // margin: 0 auto;
    // min-width: 1200px;

    @include center-content(50);
}

.center {
    flex-grow: 1;

    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: center;

    .el-button {
        padding: 0px 20px;
        font-size: 12px;
        line-height: 40px;
        border-width: 0px;
    }

    .el-button + .el-button {
        margin-left: 0;
    }
}

.logo {
    width: 170px;
    height: 60px;
    display: block;
    cursor: pointer;
}

.title {
    font-size: 24px;
    color: #000000;
    line-height: 50px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    position: relative;
    padding-left: 100px;
    font-weight: lighter;

    &::before {
        content: '';
        width: 1px;
        height: 50px;
        background: #f2f3f5;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        position: absolute;
        left: 40px;
        top: 0px;
    }
}

.connect {
    font-size: 20px;
    font-weight: bold;
    color: #818999;
    line-height: 28px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    margin-right: 10%;
}

.storeHeader {
    background-color: #fff;
    // position: fixed;
    z-index: 200;

    padding: 90px 0 0;
    min-width: 1200px;

    .storeMain {
        @include center-content(220);

        .storeInfo {
            display: flex;
            align-items: center;
            padding: 30px 0;
        }
    }
}

.storeLogo {
    width: 240px;
}

.store-center {
    flex-grow: 1;
    margin-left: 20px;
    align-self: center;
    .store-title {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-weight: bold;
        color: #292c33;
        line-height: 30px;

        .qi {
            width: 21px;
            height: 24px;
            margin-left: 5px;
        }

        .vip {
            width: 46px;
            height: 24px;
            margin-left: 5px;
        }
    }

    .text1 {
        font-size: 14px;
        font-weight: bold;
        color: #818999;
        line-height: 20px;
        margin-top: 6px;
    }

    .text2 {
        font-size: 13px;
        color: #bcc1cc;
        line-height: 18px;
        margin-top: 8px;
        &:hover {
            color: #000000;
        }
    }
}

.store-cor {
    display: flex;
    align-items: center;
    .el-button {
        margin-left: 30px;
        .iconfont {
            color: #bcc1cc;
            font-size: 32px;
            // padding: 0 6px;
        }

        &.active {
            .btn-content {
                color: #ffa526;
            }
            .iconfont {
                color: #ffa526;
            }
        }
        .btn-content {
            color: #bcc1cc;
            display: flex;
            align-items: center;
            font-size: 12px;
        }

        &:hover {
            .iconfont {
                color: $--color-warning;
            }
            .btn-content {
                color: $--color-warning;
            }
        }

        &.zixun {
            .btn-content {
                .text {
                    color: #fff;
                    font-size: 14px;
                }
            }

            .iconfont {
                color: #fff;
                font-size: 22px;
                padding: 0 6px;
            }
        }
    }

    .el-button--warning {
        min-width: 126px;
        margin-left: 50px;
    }
}

.menu {
    height: 44px;
    background: #0f264d;
    // position: fixed;
    // top: 252px;
    left: 0;
    padding: 0;
    right: 0;
    z-index: 20;
    min-width: 1200px;

    .menu-center {
        @include center-content(480);
    }

    .el-menu {
        height: 44px;
        border-bottom: 0px;
    }
}

a {
    color: inherit;
}
</style>
<style lang="scss">
@import '../../styles/variables';
.header {
    .center {
        .el-input__inner {
            border-color: $--color-primary;
            background-color: transparent;

            &:hover {
                border-color: $--color-warning;
            }

            &:focus {
                outline: none;
                border-color: $--color-warning;
            }
        }
    }
}
.store {
    position: relative;
    z-index: 20;
    .el-menu-item {
        line-height: 44px;
        height: 44px;
        border-width: 0px !important;
        color: #fff;

        &.is-active {
            color: #ffa526;
        }

        &:hover {
            color: $--color-warning !important;
        }
    }

    .el-submenu {
        .el-submenu__title {
            line-height: 44px;
            height: 44px;
            border-width: 0px !important;
            color: #fff;
            &:hover {
                color: $--color-warning !important;
            }
        }
        &.is-active {
            .el-submenu__title {
                color: #ffa526;
                i {
                    color: #ffa526;
                }
            }
        }
    }
}

.el-menu.el-menu--popup {
    background-color: #fff;
    padding: 0 0;
    .el-menu-item {
        color: #292c33;
        padding: 0 20px;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        &:hover {
            background-color: rgba(255, 165, 38, 0.1);
            color: #ffa526;
        }
    }
    .el-submenu__title {
        color: #292c33;
        padding: 0 20px;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        &:hover {
            background-color: rgba(255, 165, 38, 0.1);
            color: #ffa526;
        }
    }

    .el-submenu.is-active {
        background-color: rgba(255, 165, 38, 0.1);
        .el-submenu__title {
            color: #ffa526;
        }
    }

    .el-menu-item.is-active {
        background-color: rgba(255, 165, 38, 0.1);
        color: #ffa526;
    }

    .el-menu.el-menu--popup {
        background-color: #f5f7fa;

        .el-submenu.is-active {
            background-color: #ffa526;
            .el-submenu__title {
                color: #fff;
            }
        }

        .el-menu-item.is-active {
            background-color: #ffa526;
            color: #fff;
            &:hover {
                color: #fff;
            }
        }

        .el-menu-item {
            &:hover {
                background-color: #ffa526;
                color: #fff;
            }
        }
        .el-submenu__title {
            &:hover {
                background-color: #ffa526;
                color: #fff;
            }
        }
    }
}
</style>
